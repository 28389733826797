.btn_wrapper {
  width: 100%;
  background-color: #f3f7f2;
  border-radius: 0 0 0.25rem 0.25rem;
  display: flex;
}

.btn:hover {
  background-color: #262926 !important;
}
